import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Team from "../../../components/team";
import HomeData from "../../../data/home.json";
import TeamDynamic from "../../../data/team.json";

const TeamContainer = ({ classOption }) => {
    return (
        <div className={`team-section overflow-hidden ${classOption}`}>
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="team-content-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="TEAM"
                                title="People, <span class='text-primary'>Behind</span> the Screen"
                                // excerptClassOption="mb-5"
                                // excerpt="Pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain"
                            />
                            <p className="high-light mb-8">
                                An organization with some of the most
                                intellectual minds working together, BiafoSoft
                                believes in - “inventing advanced technologies &
                                innovating them further to invent more.” We
                                engineer smart IT & BPM solutions that
                                understand, meet and deliver business
                                expectations of our client base. Merging
                                together our years of expertise and ambitious
                                strategies, we serve the ever evolving corporate
                                world with services like Blockchain, Digital
                                Transformation, Zohoo CRM, Unity & much more.
                            </p>
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="View more"
                                path="/team"
                            />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="new-team-members">
                            {TeamDynamic &&
                                TeamDynamic.slice(0, 3).map((single, key) => {
                                    return <Team key={key} data={single} />;
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

TeamContainer.propTypes = {
    classOption: PropTypes.string,
};

TeamContainer.defaultProps = {
    classOption: "section-pb",
};

export default TeamContainer;
