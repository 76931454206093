import React from "react";
import SectionTitle from "../components/section-title";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import portFolioData from "../data/portfolio.json";

const Portfolio = () => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };
    return (
        <React.Fragment>
            <Layout>
                <SEO title="BiafoSoft – Portfolio" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Portfolio"
                        excerpt="View our portfolio to see a selection of our clients and work <br> 
                        we’ve done that helped them to take their business to the next level"
                        image="./images/banner/1.png"
                    />
                    <SectionTitle
                        classOption="title-section mb-10 pb-10 text-center"
                        subTitle="portfolio"
                        title="Our <span class='text-primary'>Portfolio</span> "
                        excerptClassOption="These are not our products. Our team has worked on the following. <br /> you can visit the site by clicking on it"
                    />

                    <div className="row custom-center bf-mb-50">
                        {portFolioData.map(({ id, title, image, link }) => (
                            <>
                                <div key={id} className="col-md-4 col-sm-12">
                                    <div className="portfolio-card">
                                        <button
                                            onClick={() => openInNewTab(link)}
                                        >
                                            <img
                                                src={image}
                                                alt={`${title}`}
                                                className="w-100"
                                            />
                                            <h3 className="title">{title}</h3>
                                        </button>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Portfolio;
