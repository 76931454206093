import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
    Box,
    Button,
    TextField,
    InputLabel,
    NativeSelect,
} from "@mui/material";

const ContactForm = () => {
    const history = useHistory();

    let appointData = {
        name: "",
        email: "",
        message: "",
        phone: "",
        company: "",
    };

    const [data, setData] = useState(appointData);
    const [serviceVal, setServiceVal] = useState();
    const [priceVal, setPriceVal] = useState();

    const changeServiceVal = (e) => {
        setServiceVal(e);
    };
    const changePriceVal = (e) => {
        setPriceVal(e);
    };

    let { name, email, message, phone, company } = data;

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response = await fetch(
                "https://v1.nocodeapi.com/nome/google_sheets/UTWrkhnuFzlsLfAc?tabId=contactData",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify([
                        [
                            name,
                            email,
                            message,
                            phone,
                            company,
                            serviceVal,
                            priceVal,
                            new Date().toLocaleString(),
                        ],
                    ]),
                }
            );

            await response.json();
            console.log("response", response);
            setData({ ...data, name: "", email: "" });
            history.push("/");
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>
            <Box
                component="form"
                sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                }}
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <div className="row w-100">
                    <div className="col-md-6 col-sm-12">
                        <TextField
                            className="w-100"
                            label="Name"
                            variant="standard"
                            name="name"
                            required
                            value={name}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <TextField
                            className="w-100"
                            label="email"
                            variant="standard"
                            name="email"
                            value={email}
                            required
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row w-100">
                    <div className="col-md-6 col-sm-12">
                        <TextField
                            className="w-100"
                            placeholder="Your Phone (Optional)"
                            label="Phone"
                            type="number"
                            variant="standard"
                            name="phone"
                            value={phone}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <TextField
                            className="w-100"
                            placeholder="Company/Organization (Optional)"
                            label="Company"
                            variant="standard"
                            name="company"
                            value={company}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row w-100 py-2">
                    <div className="col-md-6 col-sm-12">
                        <InputLabel variant="standard" htmlFor="serviceId">
                            Which Service Are You Interested In
                        </InputLabel>
                        <NativeSelect
                            defaultValue={"Which Service Are You Interested In"}
                            className="w-100"
                            name="service"
                            onChange={(event) =>
                                changeServiceVal(event.target.value)
                            }
                            value={serviceVal}
                        >
                            <option value={"3D App Development"}>
                                3D App Development
                            </option>
                            <option value={"Blockchain Service"}>
                                Blockchain Service
                            </option>
                            <option value={"Zoho Service"}>Zoho Service</option>
                            <option value={"Free Web Hosting(for 1 Year)"}>
                                Free Web Hosting(for 1 Year)
                            </option>
                            <option value={"Enterprise Software Solutions"}>
                                Enterprise Software Solutions
                            </option>
                            <option value={"Mobile App Development"}>
                                Mobile App Development
                            </option>
                            <option value={"Web App Development"}>
                                Web App Development
                            </option>
                            <option value={"Data Services"}>
                                Data Services
                            </option>
                            <option value={"UI/UX"}>UI/UX</option>
                            <option value={"DevOps"}>DevOps</option>
                        </NativeSelect>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <InputLabel variant="standard" htmlFor="budget">
                            Your Estimated Budget?
                        </InputLabel>
                        <NativeSelect
                            className="w-100"
                            defaultValue={"Your Estimated Budget?"}
                            inputProps={{
                                name: "Your Estimated Budget?",
                                id: "budget",
                            }}
                            name="price"
                            onChange={(event) =>
                                changePriceVal(event.target.value)
                            }
                            value={priceVal}
                        >
                            <option value={"$50,000 - $100,00"}>
                                $50,000 - $100,00
                            </option>
                            <option value={"$100, 000 - $200, 000"}>
                                $100,000 - $200,000
                            </option>
                            <option value={"$200,000 - $500,000"}>
                                $200,000 - $500,000
                            </option>
                            <option value={"$500,000 or above"}>
                                $500,000 or above
                            </option>
                            <option value={"Request Budget Guidance"}>
                                Request Budget Guidance
                            </option>
                        </NativeSelect>
                    </div>
                </div>
                <div className="message-area my-3 w-100">
                    <textarea
                        className="w-100"
                        name="message"
                        rows="5"
                        value={message}
                        required
                        onChange={handleChange}
                    ></textarea>
                </div>

                <div className="submitBtn w-100 text-center">
                    <Button className="w-100" type="submit" variant="outlined">
                        Submit
                    </Button>
                </div>
            </Box>
        </>
    );
};

export default ContactForm;
