import React from "react";
import SectionTitle from "../../../components/section-title";
import ServiceBox from "../../../components/service-box";
import ServiceData from "../../../data/service.json";

const ServiceListContainer = () => {
    return (
        <div className="service-section section-pt position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 pb-10 text-center"
                            subTitle="services"
                            title="Our <span class='text-primary'>Services</span> "
                            excerptClassOption="null"
                            excerpt="Today, there are many IT companies out there that will promise you the “lowest price” to win your business. But then they nickel-and-dime you while delivering substandard support, cutting corners, and not doing what you pay them to do, leaving you at risk of a cyber-attack."
                        />
                    </div>
                </div>
                <div className="row mb-n7 align-items-center">
                    {/* <div className="col-md-6 col-xl-4 mb-7"> */}
                    <>
                        {/* <div className="service-media-wrapper media-spacing-left"> */}
                        {ServiceData &&
                            ServiceData.map((single, key) => {
                                return (
                                    <>
                                        <div className="col-md-6 col-xl-4 mb-7">
                                            <ServiceBox
                                                data={single}
                                                key={key}
                                            />
                                        </div>
                                    </>
                                );
                            })}
                        {/* </div> */}
                    </>
                    {/* 
                    </div> */}

                    {/* <div className="col-xl-4 mb-7 order-md-1 order-xl-0">
                        <div className="service-media-img text-center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/service/media.png`}
                                alt="images_not_found"
                            />
                        </div>
                    </div> */}
                    {/* <div className="col-md-6 col-xl-4 mb-7">
                        <div className="service-media-wrapper media-spacing-right">
                            {ServiceData &&
                                ServiceData.slice(3, 6).map((single, key) => {
                                    return (
                                        <ServiceBox data={single} key={key} />
                                    );
                                })}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ServiceListContainer;
