import PropTypes from "prop-types";

const Testimonial = ({ data }) => {
    return (
        <div className="testimonial-slide-item swiper-slide">
            <span className="quote zmdi zmdi-quote"></span>
            <p className="testimonial-text mt-2 mb-5">{data.excerpt}</p>
            <div className="avater d-flex">
                <div className="avater-profile">
                    <img
                        src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                        alt="avater"
                    />
                </div>
                <div className="avater-info">
                    <p>{data.name}</p>
                    <span>{data.country}</span>
                </div>
            </div>
        </div>
    );
};

Testimonial.propTypes = {
    data: PropTypes.object,
};

export default Testimonial;
