import { NavLink, useLocation } from "react-router-dom";

const MainMenu = () => {
    let location = useLocation();
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                <li className={location.pathname === "/" ? "active" : ""}>
                    <NavLink exact to="/">
                        Home
                    </NavLink>
                </li>
                <li
                    className={location.pathname === "/service" ? "active" : ""}
                >
                    <NavLink to={process.env.PUBLIC_URL + "/service"}>
                        Service
                    </NavLink>
                </li>
                <li className={location.pathname === "/about" ? "active" : ""}>
                    <NavLink to={process.env.PUBLIC_URL + "/about"}>
                        About
                    </NavLink>
                </li>
                <li className={location.pathname === "/team" ? "active" : ""}>
                    <NavLink to={process.env.PUBLIC_URL + "/team"}>
                        Team
                    </NavLink>
                </li>
                <li
                    className={
                        location.pathname === "/portfolio" ? "active" : ""
                    }
                >
                    <NavLink to={process.env.PUBLIC_URL + "/portfolio"}>
                        Portfolio
                    </NavLink>
                </li>
                <li className={location.pathname === "/faq" ? "active" : ""}>
                    <NavLink to={process.env.PUBLIC_URL + "/faq"}>FAQs</NavLink>
                </li>
                <li
                    className={location.pathname === "/contact" ? "active" : ""}
                >
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}>
                        Contact
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
